@import '../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 16px 32px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $secondary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    width: 100%;
    max-width: 200px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $primary;
    }
  }

.about-page{
    .aboutus-header-section{
        min-height: calc(100vh - 87px) !important;
        height: calc(100% - 87px);
        .custom-container{
            p{
                &.full-width{
                    max-width: 100% !important;
                }
            }
        }
    }
}

.inner-header-section{
    padding: 40px 165px;
    min-height: 750px;
    background: $sky-light;
    .header-text{
        color: $white;
        margin-top: 6rem;
        h1{
            font-size: 48px;
            font-weight: 700;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-family: $font-secondary;
            font-weight: 400;
            line-height: 36px;
            margin: 25px auto;
            width: 100%;
            max-width: 65%;
        }
    }
    &.aboutus-header-section{
        background: $white;
        background: url("../../assets/images/map.png") no-repeat center;
        background-size: contain;
        padding: 0rem 0px 1rem;
        min-height: 495px;
        .header-navigation{
            background: $sky-light;
            padding: 10px 165px;
        }
        .custom-container{
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            padding: 0rem 3rem 3rem;
            h1{
                color: $black;
                margin-bottom: 1rem;
                font-size: 55px;
            }
            p{
                margin: 0px;
                color: $black;
                font-size: 32px;
            }
            .contact-info-block{
                margin-top: 5rem;
                h3{
                    font-size: 24px;
                    margin: 0px 0px 0.5rem;
                }
                p{
                    margin: 0px 0px 0.8rem;
                    font-size: 16px;
                    color: #7E8492;
                }
                a{
                    color: $primary;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $input-border;
                    &:hover{
                        color: $secondary;
                        border-bottom: 1px solid $secondary;
                    }
                }
            }
        }
    }
}
.about-blocks-section{
    position: relative;
    .custom-container{
        padding: 5rem 2rem;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        h2{
            font-size: 32px;
            font-weight: 700;
            color: $dark-blue;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-weight: 400;
            font-family: $font-secondary;
            line-height: 36px;
        }
        .about-img{
            img{
                width: 100%;
                max-width: 510px;
            }
        }
        .right{
            padding-right: 6rem;
        }
        .custom-margin{
            margin-bottom: 5rem;
        }
    }
    &::before{
        content: '';
        position: absolute;
        background: url('../../assets/images/logo-shape-right.png') top right no-repeat;
        width: 250px;
        right: 0px;
        top: 12%;
        height: 495px;
        background-size: 75%;
    }
    &::after{
        content: '';
        position: absolute;
        background: url('../../assets/images/logo-shape.png') bottom left no-repeat;
        width: 250px;
        left:0px;
        bottom: 0%;
        height: 495px;
        background-size: 75%;
    }
}
.about-partners-section{
    padding: 0rem 165px 5rem;
    background: $white;
    .partners-text-box{
        background: $white;
        border-radius: 30px;
        color: $dark-blue;
        min-height: 645px;
        position: relative;
        z-index: 1;
        left: 8rem;
        border: 1px solid $input-border;
        padding: 2rem;
        h2{
            margin: 0px 0px 20px;
            font-size: 36px;
            font-weight: 600;
        }
        p{
            font-size: 18px;
            margin: 0px 0px 60px;
            font-weight: 400;
            color: #7E8492;
            a{
                color: $secondary
            }
        }
        .about_form{
            .p-component{
                border-radius: 8px;
                border: 1px solid $light-gray;
                height: 45px;
                font-size: 16px;
                font-weight: 400;
                &.p-inputtextarea{
                    height: 130px;
                }
                &::placeholder {
                    color: #7E8492;
                    font-weight: 400;
                    font-size: 15px;
                  }
                  
                &::-ms-input-placeholder { 
                    color: #7E8492;
                    font-weight: 400;
                    font-size: 15px;
                  }
            }
            .goto-plans-button{
                @include button;
                height: auto;
            }
        }
    }
    .benefits-img{
        img{
            width: 100%;
        }
    }
    .error-message{
        color: $error-msg;
        font-size: 13px;
        margin: 5px 2px;
    }
}

// Responsive CSS

@media (min-width:768px) and (max-width:960px){
    .about-page{
        .aboutus-header-section{
            min-height: calc(100vh - 112px) !important;
            height: calc(100% - 112px);
        }
    }
}

@media (max-width:767px){
    .about-page{
        .aboutus-header-section{
            min-height: calc(100vh - 122px) !important;
            height: calc(100% - 122px);
        }
    }
}