:root {
    --font-base: "Poppins", sans-serif;
    --font-secondary: "Work Sans", sans-serif;
    --general_font_size: "20px";
    
    --primary: #6D88FF;
    --secondary: #D5244F;
    --sky-light:#8AA0FF;
    --dark-blue:#585858;
    --black: #000000;
    --white: #ffffff;
    --sub-menu: #140E1C;
    --light-gray:#eeeeee;
    --pastel-white:#FBFBFB;
    --input-border:#C3CAD9;
    --form-p:#5A7184;
    --menu-blue:#000000;
    --theme-gray: #3a476a;
    --benifit-icons-bg: #FFFFFF;
    --blue-light-40: #C4CFFF;
    --error-msg: #f00;
}      

/* fonts */
$font-base: var(--font-base);
$font-secondary: var(--font-secondary);
$general_font_size: var(--general_font_size);

/* colors */
$primary: var(--primary);
$secondary: var(--secondary);
$sky-light: var(--sky-light);
$dark-blue: var(--dark-blue);
$black: var(--black);
$white: var(--white);
$light-gray: var(--light-gray);
$pastel-white: var(--pastel-white);
$input-border: var(--input-border);
$form-p: var(--form-p);
$menu-blue: var(--menu-blue);
$theme-gray: var(--theme-gray);
$sub-menu: var(--sub-menu);
$benifit-icons-bg: var(--benifit-icons-bg);
$blue-light-40: var(--blue-light-40);
$error-msg: var(--error-msg);

$btnprimary: #6D88FF;
$input-field: #FBFBFB;
$btnprimaryover: #585858;
