@import '../../assets/scss/variables';

// **** Bundle Details Page CSS **** //

@mixin button { 
    padding: 12px 30px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $secondary;
    border: none;
    margin-top: 2rem;
    border-radius: 50px;
    width: 100%;
    max-width: 265px;
    .p-button-label{
        font-size: 16px;
        font-family: $font-base;
        font-weight: 400;
    }
    &:hover{
        background: $primary;
    }
} 

.bundle-details-section{
    h2{
        font-size: 28px !important;
        color: $primary;
        margin: 0px;
        font-weight: 500 !important;
        &.price{
            color: $secondary;
            text-align: right;
        }
    }
    .bundle-info-box{
        border-radius: 15px;
        border: 1px solid #D6EEF5;
        background: linear-gradient(180deg, #EAF9FF 0%, #FFF 100%);
        margin-top: 1rem;
        padding: 1.5rem;
        p{
            font-size: 20px;
            color: $black;
            font-weight: 400;
            margin: 0px;
            span{
                color: $secondary;
                display: block;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 3px;
            }
            .pi-info-circle{
                color: $secondary;
                font-size: 18px;
                cursor: pointer;
            }
        }
        .line{
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 2px;
                height: 45px;
                background: linear-gradient(180deg, #C1DEEA 0%, #E0F1F9 100%);
                right: 30%;
                top: 22%;
            }
        }
        ul{
            padding: 0px;
            margin: 10px 0px 0px;
            li{
                font-size: 14px;
                border-radius: 5px;
                border: 1px solid #E6EEF1;
                background: #F9FDFF;
                padding: 4px 10px;
                margin-right: 12px;
            }
        }
        .buy-button{
            @include button;
        }
    }
    .bundle-list{
        margin-top: 2rem;
        h3{
            margin: 0px 0px 20px;
            font-size: 22px;
            font-weight: 500;
            color: $primary;
            span{
                color: $secondary;
            }
        }
        .box{
            border-radius: 15px;
            border: 1px solid #EAF6FB;
            background: #F9FDFF;
            padding: 0.5rem 1.2rem;
            ul{
                margin: 10px 0px;
                padding: 0px;
                li{
                    font-size: 20px;
                    font-weight: 500;
                    color: $primary;
                    &.price{
                        color: $secondary;
                    }
                    span{
                        color: #9D9D9D;
                        font-size: 14px;
                        font-weight: 400;
                        display: block;
                    }
                }
            }
            .view-button{
                @include button;
                margin: 0px;
                padding: 8px 25px;
                box-shadow: none;
                background: $primary;
                .p-button-label{
                    font-size: 14px;
                    color: $white;
                }
                &:hover{
                    background: $secondary;
                }
            }
            &:hover{
                border: 1px solid $secondary;
                .view-button{
                    background: $secondary; 
                }
            }
        }
        .button-box{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            background: url(../../assets/images/map.png) no-repeat center;
            background-size: 100%;
            .view-more-button{
                @include button;
                border: 1px solid $primary;
                background: $white;
                padding: 12px 20px;
                margin: 0px;
                .p-button-label{
                    color: $primary;
                }
                .p-button-icon{
                    color: $primary;
                    position: relative;
                    right: 12px;
                    top: 1px;
                }
                &:hover{
                    .p-button-label{
                        color: $white;
                    }
                    .p-button-icon{
                        color: $white;
                    }
                }
            }
        }
    }
}

// Responsive CSS

@media (max-width:768px){
    .bundle-details-section{
        .bundle-info-box{
            padding: 1rem;
            .line{
                &::after{
                    display: none;
                }
            }
            ul{
                display: block !important;
                li{
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }
            p{
                font-size: 18px;
                span{
                    font-size: 15px;
                }
            }
        }
        .bundle-list{
            .box{
                .view-button{
                    padding: 8px 12px;
                }
            }
        }
       h2{
        font-size: 22px !important;
        margin: 0px !important;
        &.price{
            text-align: left;
        }
       }
    }
}
@media (min-width:769px) and (max-width:960px){
    .bundle-details-section{
        h2{
            font-size: 22px !important;
        }
    }
}
@media (min-width:1200px) and (max-width:1460px){
    .bundle-details-section{
        .bundle-info-box{
            p{
                font-size: 18px;
                span{
                    font-size: 15px;
                }
            }
        }
    }
}