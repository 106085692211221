@import '../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 24px 40px;
    box-shadow: 0px 8px 20px 0px rgba(109, 136, 255, 0.25);
    background: $primary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    width: 100%;
    max-width: 265px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $secondary;
    }
  } 

.inner-header-section{
    padding: 40px 165px;
    min-height: 750px;
    background: $sky-light;
    .header-text{
        color: $white;
        margin-top: 6rem;
        h1{
            font-size: 48px;
            font-weight: 700;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-family: $font-secondary;
            font-weight: 400;
            line-height: 36px;
            margin: 25px auto;
            width: 100%;
            max-width: 65%;
        }
    }
}
.contact-form-section{
    padding: 40px 165px;
    .form-box{
        border-radius: 30px;
        background: $white;
        box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.03);
        min-height: 450px;
        border: 1px solid $light-gray;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        margin-top: -20rem;
        padding: 3rem 6rem;
        h2{
            font-size: 24px;
            font-weight: 700;
            position: relative;
            .pi{
                font-size: 3rem;
                color: $secondary;
                position: absolute;
                left: -70px;
                top: -7px;
            }
        }
        .p-tabview{
            .p-tabview-nav{
                border-radius: 13px;
                justify-content: space-between;
                background: $white;
                border: 2px solid  $light-gray;
                padding: 0px 0px;
                .p-unselectable-text{
                    .p-tabview-nav-link{
                        border-radius: 12px;
                        padding: 0.9rem 2rem;
                        border: none;
                        margin-bottom: 0px;
                        .p-tabview-title{
                            color: $primary;
                        }
                        .pi{
                            color: $primary;
                        }
                        &:hover{
                            background: $secondary;
                            .p-tabview-title{
                                color: $white;
                            }
                            .pi{
                                color: $white;
                            }
                        }
                    }
                    &.p-highlight{
                        .p-tabview-nav-link{
                            background: $primary;
                            .p-tabview-title{
                                color: $white;
                                font-size: 15px;
                            }
                            .pi{
                                color: $white;
                            }
                        } 
                    }
                }
                .p-tabview-ink-bar{
                    display: none;
                }
            }
            .p-tabview-panels{
                padding: 1.25rem 0rem;
                .esims-section{
                    h3{
                        font-size: 22px;
                        font-weight: 700;
                    }
                    p{
                        font-size: 14px;
                        font-family: $font-secondary;
                    }
                    .esim-box{
                        border-radius: 15px;
                        background: $white;
                        box-shadow: 0px 0px 4px 0px rgba(0, 38, 77, 0.25);
                        padding: 1.5rem;
                        .user-edit-ul{
                            margin: 0px;
                            padding: 0px;
                            font-family: $font-base;
                            li{
                                list-style: none;
                                span{
                                    margin: 0px;
                                    font-size: 16px;
                                    color: $secondary;
                                    font-weight: 500;
                                    margin-right: 12px;
                                    display: block;
                                    &.active{
                                        margin-right: 0px;
                                        border-radius: 15px;
                                        background: #2CAD28;
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: $white;
                                        padding: 2px 12px;
                                    }
                                    &.inactive{
                                        background: #BBBDBF;
                                        color: #4C4C4C;
                                        margin-right: 0px;
                                        border-radius: 15px;
                                        font-size: 13px;
                                        font-weight: 500;
                                        padding: 2px 12px;
                                    }
                                }
                                .p-image{
                                    position: relative;
                                    top: 4px;
                                }
                                b{
                                    color: $secondary;
                                    font-weight: 500;
                                }
                            }
                            .styles-module_Editext__input__2-M50{
                                border: 1px solid rgb(233 233 233);
                                padding: 0.6em 1rem;
                                border-radius: 20px;
                            }
                            .styles-module_Editext__edit_button__310_J{
                                min-width: 0px;
                                padding: 0px;
                                border-radius: 30px;
                                width: 26px;
                                height: 26px;
                                text-align: center;
                                border: none;
                                background: #deecf1;
                                margin-left: 6px;
                                &:hover{
                                    background: $primary;
                                    &::before{
                                        color: $white;
                                    }
                                }
                            }
                            .styles-module_Editext__button__sxYQX{
                                border-radius: 30px;
                                border: none;
                                font-size: 13px;
                                height: 28px;
                                width: 28px;
                                padding: 0px;
                                line-height: 16px;
                                &::before{
                                    font-weight: 600;
                                    margin: 0px;
                                }
                                &.styles-module_Editext__save_button__1Dlwo{
                                    padding: 0.5em;
                                    &:hover{
                                        background: $primary;
                                    }
                                }
                                &.styles-module_Editext__cancel_button__26sqr{
                                    padding: 0.5em;
                                    &:hover{
                                        background: $secondary;
                                    }
                                }
                                &:hover{
                                    &::before{
                                        color: $white;
                                    }
                                }
                            }
                            .status-box {
                                padding: 2px 12px;
                                font-size: 14px;
                                border-radius: 15px;
                                font-weight: 400;
                                &.Disabled{
                                    background: #e7e7e7;
                                    color: $primary;
                                }
                                &.Installed{
                                    background: #499146;
                                    color: $white;
                                }
                                &.Released{
                                    background: $primary;
                                    color: $white;
                                }
                                &.Enabled{
                                    background: #D3D9FF;
                                    color: #33409C;
                                }
                                &.Downloaded{
                                    background: #33409C;
                                    color: $white;
                                }
                                &.Unavailable{
                                    background: #FBE0DA;
                                    color: #E12D06;
                                }
                                &.Unknown{
                                    background: #050505;
                                    color: $white;
                                }
                            }

                        }
                        .id_p{
                            margin: 15px 0px 15px;
                            font-size: 15px;
                            font-weight: 500;
                        }
                        .progress-bar{
                            margin-top: 10px;
                            p{
                                margin: 5px 0px;
                                font-size: 15px;
                                font-weight: 400;
                                max-width: 100%;
                                span{
                                    font-weight: 600;
                                }
                            }
                            .p-progressbar{
                                height: 8px;
                                .p-progressbar-value{
                                    background: $primary;
                                    .p-progressbar-label{
                                        display: none;
                                    }
                                }
                            }
                            .data-span{
                                position: relative;
                                top: 18px;
                                color: $primary;
                            }
                        }
                        .esim-not-installed{
                            border-radius: 20px;
                            box-shadow: 0px 0px 4px 0px rgba(0, 61, 112, 0.05);
                            background: #feebee;
                            margin: 15px 0px;
                            padding: 1rem 2rem;
                            text-align: center;
                            .pi{
                                color: $secondary;
                                font-size: 20px;
                                cursor: pointer;
                                transition: all 0.3s ease;
                                &:hover{
                                    color: $primary;
                                    transform: scale(1.2);
                                }
                            }
                            p{
                                font-size: 16px;
                                font-weight: 500;
                                margin: 8px auto;
                                &.font-weight-light{
                                    font-weight: 300;
                                }
                            }
                        }
                        .active-esim-button{
                            padding: 27px 0px;
                        }
                        .buttons-ul{
                            margin: 1.3rem 0px 0px;
                            padding: 0px;
                            li{
                                list-style: none;
                                width: 100%;
                                max-width: 49%;
                                button{
                                    border-radius: 25px;
                                    border: 0.5px solid $secondary;
                                    background: rgba(255, 255, 255, 0.10);
                                    width: 100%;
                                    padding: 8px 1rem;
                                    .p-button-label{
                                        font-size: 14px;
                                        font-weight: 600;
                                        color: $secondary;
                                    }
                                    .p-button-icon{
                                        color: $secondary;
                                    }
                                    &:hover{
                                        background: $primary;
                                        border: 0.5px solid $primary;
                                        .p-button-label{
                                            color: $white;
                                        }
                                        .p-button-icon{
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                        &.not-installed-esim-box{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            .pi{
                                font-size: 3rem;
                                color: $secondary;
                                cursor: pointer;
                                &:hover{
                                    color: $primary;
                                }
                            }
                            p{
                                font-size: 16px;
                                color: $secondary; 
                                font-weight: 600;
                                cursor: pointer;
                                display: table-cell;
                                position: relative;
                                top:8px;
                                &:hover{
                                    color: $primary;
                                }
                            }
                        }
                        .multiple-bundles-btn{
                            @include button;
                            max-width: 300px;
                            padding: 10px 20px;
                            margin: 1rem auto 1rem;
                            .p-button-label{
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }
                        &.bundles-list-height{
                            min-height: 125px;
                        }
                    }
                }
                .profile-section{
                    .esim-box{
                        min-height: auto;
                        padding: 1rem 0rem;
                        label{
                            font-size: 14px;
                            font-weight: 500;
                            .pi{
                                color: $secondary;
                                position: relative;
                                top: 1px;
                                font-size: 14px;
                                margin-left: 6px;
                            }
                        }
                        .p-input-icon-right{
                            .pi{
                                color: $primary;
                                font-size: 14px;
                                &.pi-pencil{
                                    font-size: 14px;
                                }
                            }
                            .p-password{
                                i{
                                    svg{
                                        color: $primary;
                                        width: 14px;
                                    }
                                }
                            }
                        }
                        .p-inputtext{
                            border-radius: 15px;
                            border: 1px solid rgba(0, 82, 162, 0.25);
                            background: $white;
                            height: 40px;
                            font-size: 14px;
                            font-weight: 400;
                            color: $secondary;
                            &.p-password-input{
                                width: 100%;
                            }
                        }
                        .update-button{
                            @include button;
                            padding: 15px 40px;
                            height: 50px;
                            margin-top: 1.5rem;
                            .p-button-label{
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }
                        .error-message{
                            margin: 0px 2px;
                        }
                    }
                    .custom-phoneno-field{
                        width: 100%;
                        .react-tel-input{
                            margin-bottom: 8px;
                            .form-control {
                                width: 100%;
                                height: 40px;
                                border-radius: 15px;
                                border: 1px solid rgba(0, 82, 162, 0.25);
                                background: $white;
                            }
                            .flag-dropdown{
                                border-radius: 15px 0 0 15px;
                                .selected-flag{
                                    border-radius: 15px 0 0 15px;
                                }
                            }
                        }
                    }
                }
                .notifications-tab{
                    position: relative;
                    h2{
                        font-size: 20px;
                    }
                    .notifications-list{
                        border-radius: 15px;
                        background: $white;
                        box-shadow: 0px 0px 5px 1px rgba(0, 38, 77, 0.06);
                        padding: 0.8rem 1.5rem;
                        margin: 15px 0px 25px;
                        position: relative;
                        h3{
                            margin: 0px 0px 10px;
                            font-weight: 600;
                            font-size: 18px;
                            color: $primary;
                        }
                        p{
                            font-size: 15px;
                            font-family: $font-secondary;
                            line-height: 22px;
                            margin: 7px 0px;
                            span{
                                color: #b1b1b1;
                                font-size: 14px;
                            }
                            .pi-calendar{
                                font-size: 14px;
                                color: $input-border;
                            }
                        }
                        .read-button{
                            padding: 10px 30px 6px;
                            background: $white;
                            border: 1px solid $secondary;
                            box-shadow: none;
                            margin: 10px 0px 0px 0px;
                            .p-button-label{
                                color: $secondary;
                                font-weight: 500;
                                font-size: 14px;
                            }
                            &:hover{
                                background: $primary;
                                border: 1px solid $primary;
                                .p-button-label{
                                    color: $white;
                                }
                            }
                            &.unread{
                                border: 1px solid $primary;
                                .p-button-label{
                                    color: $primary;
                                }
                                &:hover{
                                    background: $secondary;
                                    border: 1px solid $secondary;
                                    .p-button-label{
                                        color: $white;
                                    }
                                }
                            }
                        }
                        .p-inplace-content-close{
                            padding: 5px 45px;
                            box-shadow: none;
                            margin: 10px 0px 0px 0px;
                            background: $secondary;
                            border: none;
                            border-radius: 25px;
                            span{
                                color:$white;
                                font-size: 15px;
                                &.p-button-icon{
                                    &::before{
                                        content: "Close";
                                        font-family: $font-base;
                                    }
                                }
                            }
                            &:hover{
                                background: $primary;
                                .p-button-label{
                                    color: $white;
                                }
                            }
                        }
                        .p-inplace{
                            .p-inplace-display{
                                padding: 0px;
                                &:hover{
                                    background: none;
                                }
                            }
                        }
                        &::before{
                            content: '';
                            position: absolute;
                            width: 8px;
                            height: 8px;
                            border-radius: 20px;
                            left: -4px;
                            top: 48%;
                        }
                        &.opened{
                            opacity: 0.65;
                            &::before{
                                display: none;
                            }
                        }
                        .bg-contain{
                            width: 100%;
                            overflow: auto;
                            .qr-code-image{
                                width: 100%;
                            }
                        }
                    }
                    .pagination{
                        margin: 0px;
                        padding: 0px;
                        li{
                            list-style: none;
                            padding: 5px 15px;
                            background: #e0e6f3;
                            margin: 0px 10px 0px 0px;
                            border-radius: 5px;
                            color: $primary;
                            cursor: pointer;
                            font-size: 14px;
                            &:hover{
                                background: $primary;
                                color: $white;
                            }
                            &.page-no{
                                border-radius: 30px;
                                width: 30px;
                                height: 30px;
                                text-align: center;
                                padding: 0px;
                                line-height: 30px;
                                background: $primary;
                                color: $white;
                            }
                            &.disabled{
                                opacity: 0.6;
                                pointer-events: none;
                            }
                        }
                    }
                    .loading{
                        width: 100%;
                        height: 100%;
                        background: $sky-light;
                        position: absolute;
                        left: 0px;
                        right: 0px;
                        top: 0px;
                        bottom: 0px;
                        min-height: 215px;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .pi-spin{
                            color: $primary;
                            font-size: 30px;
                            font-weight: 600;
                        }
                    }
                }
                .breadcrumbs-ul{
                    margin: 0px;
                    padding: 0px;
                    li{
                        list-style: none;
                        display: inline-block;
                        margin: 0px 8px 0px 0px;
                        font-size: 15px;
                        font-family: $font-secondary;
                        a{
                            color: $primary;
                            &:hover{
                                color: $primary;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .card{
                    .p-dropdown{
                        border: 1px solid rgba(0, 82, 162, 0.25);
                        border-radius: 20px;
                        min-width: 200px;
                        .p-dropdown-label{
                            padding: 10px 15px;
                            font-size: 15px;
                            font-family: $font-secondary;
                            position: relative;
                            top: 3px;
                        }
                    }
                }
                .myaccount-tab{
                    position: relative;
                    .loading-div{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0px;
                        right: 0px;
                        top: 50px;
                        bottom: 0px;
                        background: $sky-light;
                        min-height: 180px;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                        .pi-spin{
                            color: $white;
                            font-size: 30px;
                            font-weight: 600;
                        }
                    }
                    .esims-section{
                        display: flow-root;
                        margin-top: 1rem;
                        .status-dropdown{
                            position: absolute;
                            right: 0px;
                            top:0px;
                        }
                        .search-box{
                            h2{
                                font-size: 24px;
                                font-weight: 700;
                                position: relative;
                                .pi{
                                    font-size: 3rem;
                                    color: $secondary;
                                    position: absolute;
                                    left: -70px;
                                    top: -7px;
                                    cursor: pointer;
                                }
                            }
                            .search-field{
                                position: relative;
                                width: 100%;
                                max-width: 300px;
                                .pi-search{
                                    margin-left: 6px;
                                    z-index: 1;
                                }
                                .p-component{
                                    border: 2px solid rgba(109, 136, 255, 0.25);
                                    border-radius: 25px;
                                    width: 100%;
                                    height: 45px;
                                }
                                .p-dropdown-label{
                                    padding-left: 2.7rem;
                                    text-align: left;
                                }
                                .search-button{
                                    position: absolute;
                                    right: 4px;
                                    border-radius: 30px;
                                    padding: 11px 30px;
                                    top: 3px;
                                    background: $secondary;
                                    border: none;
                                    .p-button-label{
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                    &:hover{
                                        background: $primary;
                                    }
                                }
                            }
                            .info-section{
                                .info-box{
                                    padding: 5px 0px;
                                    margin-bottom: 1rem;
                                    p{
                                        margin: 0px;
                                        font-family: $font-secondary;
                                        color: $primary;
                                        font-size: 16px;
                                        &.infoText{
                                            font-style: italic;
                                            position: relative;
                                            display: flex;
                                            align-items: center;
                                            .info-icon{
                                                background: $secondary;
                                                border-radius: 50px;
                                                color: $white;
                                                display: block;
                                                height: 20px;
                                                line-height: 20px;
                                                text-align: center;
                                                width: 20px;
                                                min-width: 20px;
                                                margin-right:15px;
                                                top: 0px;
                                                left: 10px;
                                                position: relative;
                                                .pi-info{
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                        &.iccidText{
                                            span{
                                                font-weight: 500;
                                            }
                                        }
                                    }
                                    ul{
                                        margin: 0px;
                                        padding: 5px 0px;
                                        li{
                                            list-style: none;
                                            display: inline-block;
                                            margin-right: 12px;
                                            color: $input-border;
                                        }
                                    }
                                }
                            }
                        }
                        .topup-table{
                            .p-datatable{
                                border: 1px solid #ddeef5;
                                .p-datatable-thead{
                                    > tr {
                                        > th{
                                            background: $sky-light;
                                            padding: 1.5rem 1rem;
                                            .p-column-title{
                                                color: $white;
                                                font-size: 15px;
                                                font-weight: 600;
                                            }
                                            &:first-child{
                                                padding: 1.5rem 2rem;
                                            }
                                        }
                                    }
                                }
                                .p-datatable-tbody{
                                    tr{
                                        td{
                                            border-bottom: 1px solid #ddeef5;
                                            p{
                                                margin: 0px 0px 3px;
                                                color: #222B45;
                                                font-size: 14px;
                                                font-weight: 500;
                                            }
                                            span{
                                                font-size: 14px;
                                                color: #8F9BB3;
                                            }
                                            &.first-column{
                                                padding: 1rem 2rem;
                                            }
                                            button{
                                                border-radius: 15px;
                                                background: $secondary;
                                                border: none;
                                                padding: 0.75rem 2rem;
                                                .p-button-label{
                                                    color: $white;
                                                    font-size: 13px;
                                                    font-weight: 600;
                                                }
                                                &:hover{
                                                    background: $primary;
                                                }
                                            }
                                        }
                                        &:last-child{
                                            td{
                                                border: none;
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                    }
                }
        }
    }
}

.forms-dialog-popup{
    background-color: #fff;
}

.esim-popup-title{
    font-weight: 900;
    font-size: 18px;
    color: #050505;
    text-align: center;
}
.esim-popup-subtitle{
    font-weight: 300;
    font-size: 15px;
    text-align: center;
    padding-left:5%;
    padding-right:5%;
    line-height: 1.3;
}
.esim-popup-button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.choose-popup-button {

    border-radius: "100%";
    width: 130px;
    margin-bottom: 6%;
}
.multiple-bundles-dialog{
    .active-bundles-box{
        border-radius: 15px;
        background: $white;
        box-shadow: inset 0px 0px 5px 1px rgba(0, 38, 77, 0.08);
        padding: 1rem 1.5rem;
        margin: 15px 0px;
        .progress-bar{
            margin-top: 10px;
            p{
                margin: 5px 0px;
                font-size: 15px;
                font-weight: 400;
                span{
                    font-weight: 600;
                }
            }
            .p-progressbar{
                height: 8px;
                .p-progressbar-value{
                    background: $secondary;
                    .p-progressbar-label{
                        display: none;
                    }
                }
            }
        }
        .user-edit-ul{
            margin: 0px 0px 15px;
            padding: 0px;
            font-family: $font-base;
            li{
                list-style: none;
                span{
                    margin: 0px;
                    font-size: 16px;
                    color: $secondary;
                    font-weight: 500;
                    margin-right: 12px;
                }
                b{
                    color: $secondary;
                    font-weight: 500;
                }
            }
        }
    }
}
.order-details-dialog{
    font-family: $font-base;
    .p-dialog-header{
        border-radius: 2px 2px 0px 0px;
    }
    .p-dialog-content{
        border-radius: 0px 0px 2px 2px;
        .plan-details-box{
            background: $blue-light-40;
            padding: 1rem 1.5rem;
            border-radius: 15px;
            border: 2px dotted $form-p;
            ul{
                margin: 0px;
                padding: 0px;
                li{
                    list-style: none;
                    h3{
                        margin: 0px 0px 2px;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    span{
                        font-size: 15px;
                        &.esimNonCompatible{
                            font-family: $font-secondary;
                            color: $secondary;
                            font-style: italic;
                        }
                        .info-btn{
                            position: relative;
                            top: 2px;
                            left: 5px;
                            cursor: pointer;
                        }
                        &.price{
                            font-size: 20px;
                            color: $secondary;
                            font-weight: 600;
                        }
                        &.promoCode{
                            font-size: 15px;
                            color: $theme-gray;
                        }
                        &.arrow {
                            font-size: 15px;
                            color: $theme-gray;
                        }
                        &.discount{
                            font-size: 20px;
                            font-weight: 600;
                            text-decoration: line-through solid;
                        }
                    }
                }
            }
        }
        .order-details-ul{
            margin: 5px 0px 20px !important;
            padding: 0px;
            li{
                list-style: none;
                font-size: 15px;
                b{
                    display: block;
                    font-size: 14px;
                    margin-bottom: 2px;
                }
                .status-box{
                    background: $input-border;
                    padding: 2px 6px;
                    font-size: 12px;
                    border-radius: 2px;
                    &.COMPLETED{
                        background: #499146;
                        color: $white;
                    }
                    &.PENDING{
                        background: #F9F2DF;
                        color: $white;
                    }
                    &.FAILED{
                        background: #FBE0DA;
                        color: $white;
                    }
                }
            }
        }
        .details-section{
            margin-top: 1.5rem;
            .details-box{
                border-radius: 20px;
                border: 2px solid #eeeeee;
                background: $white;
                box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
                padding: 1rem 1rem;
                height: 100%;
                h3{
                    margin: 0px 0px 15px;
                    font-size: 18px;
                    font-weight: 600;
                    color: $secondary;
                }
                ul{
                    margin: 0px;
                    padding: 0px;
                    li{
                        list-style: none;
                        font-size: 15px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid #f3f3f3;
                        padding-bottom: 5px;
                        word-wrap: break-word;
                        b{
                            display: block;
                            color: #9b9b9b;
                            font-size: 13px;
                            font-weight: 500;
                        }
                        &:last-child{
                            border: none;
                            margin-bottom: 0px;
                        }
                        .status-box{
                            background: $input-border;
                            padding: 2px 6px;
                            font-size: 12px;
                            border-radius: 2px;
                            &.COMPLETED{
                                background: #499146;
                                color: $white;
                            }
                            &.PENDING{
                                background: #F9F2DF;
                                color: $white;
                            }
                            &.FAILED{
                                background: #FBE0DA;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
.paymentsummary-qr-section{
    text-align: center;
    padding: 0px 2rem;
    .scan-text{
        display: block;
        color: $black;
        font-size: 15px;
        a{
            color: $menu-blue;
            border-bottom:1px solid $menu-blue;
        }
    }
    p{
        color: $black;
        font-size: 15px;
    }
    .qr-code{
        padding: 5px;
        background: $sub-menu;
        border-radius: 10px;
        margin: 1rem 0rem;
    }
    .download-button{
        border-radius: 24px;
        border: none;
        box-shadow: 0px 24px 40px -12px rgba(0, 0, 0, 0.08);
        width: 100%;
        max-width: 200px;
        margin-left: 10px;
        background: $secondary;
        margin-top: 1rem;
        .p-button-label{
            font-size: 15px;
            font-weight: 600;
            color: $white;
        }
        &:hover{
            background: $primary;
        }
    }
}
.p-tooltip .p-tooltip-text{
    padding: 0.2rem 0.75rem;
    font-size: 14px;
    background: $primary;
}
.p-tooltip .p-tooltip-arrow{
    border-top-color:$primary;
}

@media (max-width:620px){
    .contact-form-section{
        .form-box{
            .p-tabview{
                .p-tabview-nav{
                    display: block;
                }
                .p-tabview-panels{
                    .myaccount-tab{
                        .loading-div{
                            min-height: 95px;
                        }
                        .esims-section{
                            .status-dropdown{
                                position: relative;
                                margin: 20px 0px 10px;
                            }
                            .search-box{
                                .info-section{
                                    .info-box{
                                        p{
                                            font-size: 14px;
                                        }
                                        ul{
                                            li{
                                                &:nth-child(2){
                                                    display: none;
                                                }
                                                .iccidText{
                                                    margin-left: 1.5rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .esims-section{
                        .esim-box{
                            padding: 1rem;
                            .buttons-ul{
                                display: block !important;
                                li{
                                    max-width: 100%;
                                    margin: 1rem 0px;
                                }
                            }
                            .id_p{
                                font-size: 14px;
                            }
                        }
                    }
                    .notifications-tab{
                        .loading{
                            min-height: 135px;
                        }
                    }
                    .breadcrumbs-ul{
                        li{
                            margin: 0px 5px 0px 0px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:768px){
    .download-qr-dialog{
        width: 100%;
        max-width: 80%;
        .paymentsummary-qr-section{
            .qr-code{
                width: 100%;
                max-width: 250px;
            }
        }
    }
    .order-details-dialog{
        width: 100% !important;
        max-width: 85%;
        .p-dialog-content{
            .order-details-ul{
                display: block !important;
                li{
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .esims-section{
        .topup-table{
            overflow: scroll;
            .p-datatable-table{
                width: 100% !important;
                tr{
                    &:last-child{
                        td{
                            border-bottom: 1px solid #eeeeee !important;
                        }
                    }
                }
            }
            .p-datatable .p-datatable-tbody > tr{
                display: grid;
                border: 1px solid #eee;
                td{
                    &.first-column{
                        padding: 1rem 1rem !important;
                        background: #C1DEEA;
                    }
                }
            }
            .p-datatable .p-datatable-thead{
                display: none;
            }
        }
    }
    .contact-form-section .form-box .p-tabview .p-tabview-panels{
        .esims-section .esim-box .user-edit-ul .status-box{
            font-size: 12px;
            padding: 2px 8px;
            margin-right: 0px;
        }
        .notifications-tab .notifications-list{
            padding: 0.8rem 1rem;
            .bg-contain{
                display: block !important;
            }
        }
    }
    .multiple-bundles-dialog .active-bundles-box .user-edit-ul{
        display: block !important;
    }
    .order-details-dialog .p-dialog-content .plan-details-box ul{
        display: block !important;
        li{
            margin-bottom: 5px;
        }
    }
}
@media (min-width:769px) and (max-width:1199px){
    .order-details-dialog{
        width: 100% !important;
        max-width: 85%;
        .p-dialog-content{
            .order-details-ul{
                display: block !important;
                li{
                    margin-bottom: 1rem;
                    display: inline-block;
                    width: 49%;
                }
            }
        }
    }
    .contact-form-section{
        .form-box{
            .p-tabview{
                .p-tabview-panels{
                    .myaccount-tab{
                        .esims-section{
                            .search-box{
                                .info-section{
                                    .info-box{
                                        p{
                                            &.infoText{
                                                max-width: 100%;
                                            }
                                            &.iccidText{
                                                max-width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:1200px) and (max-width:1460px){
    .contact-form-section{
        .form-box{
            padding: 3rem 3rem;
            .p-tabview{
                .p-tabview-panels{
                    .esims-section{
                        .esim-box{
                            .active-esim-button{
                                padding: 36px 0px;
                            }
                        }
                    }
                    .myaccount-tab{
                        .esims-section{
                            .search-box{
                                .info-section{
                                    .info-box{
                                        p{
                                            &.infoText{
                                                max-width: 100%;
                                            }
                                            &.iccidText{
                                                max-width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .order-details-dialog{
        width: 100% !important;
        max-width: 85%;
    }
}
@media (min-width:769px) and (max-width:960px){
    .contact-form-section{
        .form-box {
            .p-tabview {
                .p-tabview-panels {
                    .esims-section{
                        .esim-box{
                            padding: 1rem;
                            .buttons-ul{
                                display: block !important;
                                li{
                                    max-width: 100%;
                                    margin: 1rem 0px;
                                }
                            }
                            .id_p{
                                font-size: 14px;
                            }
                            .active-esim-button{
                                padding: 36px 0px;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}
@media (max-width:970px){
    .notifications-list{
        .p-inplace{
            .p-inplace-content{
                .bg-contain{
                    overflow: scroll;
                }
            }
        }
    }
    .esims-section{
        .topup-table{
            overflow: scroll;
            .p-datatable-table{
                width: 980px;
            }
        }
    }
    .multiple-bundles-dialog{
        width: 100% !important;
        max-width: 85%;
    }
}
@media (min-width:960px) and (max-width:1199px){
    .contact-form-section .form-box .p-tabview .p-tabview-panels .esims-section .esim-box{
        .buttons-ul{
            li{
                button{
                    padding: 8px 1rem;
                    .p-button-label{
                        font-size: 14px;
                    }
                }
            }
        }
        .active-esim-button{
            padding: 36px 0px;
        }
    }
    .multiple-bundles-dialog{
        width: 60% !important;
    }
}