@import '../../assets/scss/variables';

.contact-section {
    background: $sky-light;
}
.global-esims-page{
    .full-width{
        &.p-text{
            max-width: 95%;
        }
    }
    .buynow-button{
        padding: 25px 45px;
        box-shadow: 0px 8px 20px 0px rgba(109, 136, 255, 0.25);
        background: $secondary;
        border-radius: 50px;
        color: $white;
        font-size: 18px;
        font-weight: 600;
        display: inline-block;
        min-width: 275px;
        text-align: center;
        margin-top: 1rem;
        &:hover{
            background: $primary;
        }
    }
}
